// import Vue from 'vue'; import i18n from '@/i18n';
import { CallCommand, Field } from '@/util/dynamicForm/dynamic-form';
export class GetLinkedGeneralNotices extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarVinculosAvisoGeral',
            type: 'SEARCH',
            node: 'AVISOS_GERAIS.AVISO_GERAL',
            fields: {
                userId: new Field({
                    name: 'ID_USUARIO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetGeneralNotice extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarAvisoGeral',
            type: 'SEARCH',
            node: 'AVISOS_GERAIS.AVISO_GERAL',
            fields: {
                generalNoticeId: new Field({
                    name: 'ID_AVISO_GERAL',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveGeneralNotice extends CallCommand {
    constructor() {
        super({
            name: 'SalvarAvisoGeral',
            type: 'SAVE',
            fields: {
                generalNoticeId: new Field({
                    name: 'ID_AVISO_GERAL',
                }),
                generalNoticeDescription: new Field({
                    name: 'DESCRICAO_AVISO_GERAL',
                }),
                generalNoticeMessage: new Field({
                    name: 'MENSAGEM_AVISO_GERAL',
                }),
                startDate: new Field({
                    name: 'DATA_INICIO',
                }),
                expirationDate: new Field({
                    name: 'DATA_VALIDADE',
                }),
                bannerColor: new Field({
                    name: 'COR_BANNER',
                }),
                fontColor: new Field({
                    name: 'COR_FONTE',
                }),
                fontSize: new Field({
                    name: 'TAMANHO_FONTE',
                }),
                active: new Field({
                    name: 'ATIVO',
                }),
                userGroupIds: new Field({
                    name: 'IDS_GRUPO_PERMISSOES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class DeleteGeneralNotice extends CallCommand {
    constructor() {
        super({
            name: 'ExcluirAvisoGeral',
            type: 'DELETE',
            fields: {
                generalNoticeId: new Field({
                    name: 'ID_AVISO_GERAL',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetInstanceConfig extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarConfiguracoesInstancia',
            type: 'SEARCH',
            node: 'CONFIG',
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveInstanceConfig extends CallCommand {
    constructor() {
        super({
            name: 'SalvarConfiguracoesInstancia',
            type: 'SAVE',
            node: 'CONFIGS.CONFIG',
            fields: {
                cornerDefaultBoundBegin: new Field({
                    name: 'COORDENADA_PADRAO_MAPA_INICIO',
                }),
                cornerDefaultBoundEnd: new Field({
                    name: 'COORDENADA_PADRAO_MAPA_FIM',
                }),
                googleMapsAddressing: new Field({
                    name: 'ENDERECAMENTO_GOOGLEMAPS',
                }),
                chatbotInitialMessage: new Field({
                    name: 'MENSAGEM_INICIAL_CHATBOT',
                }),
                instanceCountryId: new Field({
                    name: 'ID_PAIS',
                }),
                currencyId: new Field({
                    name: 'ID_MOEDA',
                }),
                allowDeliveriesWithKitsLooseMaterials: new Field({
                    name: 'PERMITIR_ENTREGAS_KITS_MATERIAIS_AVULSOS',
                }),
                allowAlterationRefuellingRecordsWithFinancialEvents: new Field({
                    name: 'PERMITE_ALTERACAO_REGISTRO_ABASTECIMENTO_COM_EVENTO_FINANCEIRO',
                }),
                generateProjectBaseDuringSync: new Field({
                    name: 'GERAR_BASE_OBRAS_DURANTE_SINCRONIZACAO',
                }),
                localeDefault: new Field({
                    name: 'LOCALE_DEFAULT',
                }),
                streetViewEnabled: new Field({
                    name: 'STREET_VIEW_HABILITADO',
                }),
                enableGeocodeCache: new Field({
                    name: 'HABILITAR_CACHE_GEOCODE',
                }),
                mvrp: new Field({
                    name: 'MVRP',
                }),
                mvrpMode: new Field({
                    name: 'MVRP_MODO',
                }),
                mvrpAllWorksites: new Field({
                    name: 'MVRP_DISPONIVEL_TODOS_PARQUES',
                }),
                disableInstance: new Field({
                    name: 'DESABILITAR_INSTANCIA',
                }),
                specialStockMovementValueMandatory: new Field({
                    name: 'OBRIGATORIO_PRECO_MOVIMENTO_ESPECIAL_ESTOQUE_ENTRADA',
                }),
                showPurchaseMvrp: new Field({
                    name: 'MOSTRAR_COMPRA_MVRP',
                }),
                purchaseMvrpLink: new Field({
                    name: 'LINK_COMPRA_MVRP',
                }),
                antiVirusEnabled: new Field({
                    name: 'ANTI_VIRUS_HABILITADO',
                }),
                chatbotMode: new Field({
                    name: 'CHATBOT_MODO',
                }),
                iotHubEnabled: new Field({
                    name: 'IOTHUB_HABILITADO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveInitialConfigs extends CallCommand {
    constructor() {
        super({
            name: 'SalvarConfiguracoesIniciais',
            type: 'SAVE',
            fields: {
                inclusionType: new Field({
                    name: 'TIPO_INCLUSAO',
                }),
                defaultFinancialAccountId: new Field({
                    name: 'ID_CONTA_FINANC_DEFAULT',
                }),
                defaultContractCategoryId: new Field({
                    name: 'ID_CATEGORIA_FINANCEIRA',
                }),
                defaultStockcenterId: new Field({
                    name: 'ID_CENTRO_ESTOQUE_DEFAULT',
                }),
                financialHolidayCalendarId: new Field({
                    name: 'ID_CALENDARIO_FERIADOS_FINANCEIRO',
                }),
                hrHolidayCalendarId: new Field({
                    name: 'ID_CALENDARIO_FERIADOS_RH',
                }),
                nfeIoIntegratedCompanyId: new Field({
                    name: 'ID_EMPRESA_NFEIO',
                }),
                alocationMovementContracts: new Field({
                    name: 'MOV_ALOC_CONTRATOS',
                }),
                alocationMovementPurchases: new Field({
                    name: 'MOV_ALOC_COMPRAS',
                }),
                alocationMovementSelfConference: new Field({
                    name: 'MOV_ALOC_CONFERENCIA_PROPRIA',
                }),
                billServiceActive: new Field({
                    name: 'NF_SERVICO_ATIVA',
                }),
                culturalPromoter: new Field({
                    name: 'INCENTIVADOR_CULTURAL',
                }),
                categoryMonthly: new Field({
                    name: 'CATEG_MES_A_MES',
                }),
                defaultBdiMaterials: new Field({
                    name: 'BDI_MATERIAIS_DEFAULT',
                }),
                defaultBdiThirdparties: new Field({
                    name: 'BDI_TERCEIROS_DEFAULT',
                }),
                defaultFinancialContractCategoryId: new Field({
                    name: 'ID_CATEGORIA_FINANCEIRA',
                }),
                defaultPriceUSUrban: new Field({
                    name: 'PRECO_US_URBANA_DEFAULT',
                }),
                defaultPriceUSRural: new Field({
                    name: 'PRECO_US_RURAL_DEFAULT',
                }),
                fileMaxSize: new Field({
                    name: 'TAMANHO_MAXIMO_ARQUIVO',
                }),
                financialActive: new Field({
                    name: 'FINANCEIRO_ATIVO',
                }),
                ignoreFinancialSchedule: new Field({
                    name: 'IGNORAR_AGEND_FINANCEIRO',
                }),
                mandatoryCategorization: new Field({
                    name: 'ALOC_FINANC_PEDIDO_APROV',
                }),
                mandatoryCategoryConfirmation: new Field({
                    name: 'ALOC_FINANC_APROV',
                }),
                mapVisibilityTime: new Field({
                    name: 'TEMPO_VISIBILIDADE_MAPA',
                }),
                maxValueAdjustment: new Field({
                    name: 'VALOR_AJUSTE',
                }),
                releaseNonApprovedSchedule: new Field({
                    name: 'LIBERAR_AGEND_NAO_APROVADO',
                }),
                retentionPercentageINSS: new Field({
                    name: 'PERC_RETENCAO_INSS',
                }),
                retentionPercentageIR: new Field({
                    name: 'PERC_RETENCAO_IR',
                }),
                retentionPercentagePIS: new Field({
                    name: 'PERC_RETENCAO_PIS',
                }),
                retentionPercentageCOFINS: new Field({
                    name: 'PERC_RETENCAO_COFINS',
                }),
                retentionPercentageCSLL: new Field({
                    name: 'PERC_RETENCAO_CSLL',
                }),
                retentionPercentageOTHERS: new Field({
                    name: 'PERC_RETENCAO_OUTRAS',
                }),
                percentagePIS: new Field({
                    name: 'PERC_PIS',
                }),
                percentageCONFIS: new Field({
                    name: 'PERC_CONFIS',
                }),
                percentageCSLL: new Field({
                    name: 'PERC_CSLL',
                }),
                percentageIRLP: new Field({
                    name: 'PERC_IRLP',
                }),
                nationalSimpleOptant: new Field({
                    name: 'SIMPLES_NACIONAL',
                }),
                scheduleFinancialContracts: new Field({
                    name: 'AGEND_FINANC_CONTRATOS',
                }),
                scheduleFinancialPurchases: new Field({
                    name: 'AGEND_FINANC_COMPRAS',
                }),
                writableLineBillet: new Field({
                    name: 'LINHA_DIGITAVEL_BOLETO',
                }),
                ARTResponsibleEmail: new Field({
                    name: 'EMAIL_RESPONSAVEL_ART',
                }),
                apiKeyNFEIO: new Field({
                    name: 'CHAVE_API_NFEIO',
                }),
                itemBarCodeType: new Field({
                    name: 'TIPO_COD_BARRAS_ITEM',
                }),
                financialResponsibleEmail: new Field({
                    name: 'EMAIL_RESPONSAVEL_FINANCEIRO',
                }),
                minimumDateCategorization: new Field({
                    name: 'DATA_MINIMA_CATEGORIZACAO',
                }),
                purchaseOrderText: new Field({
                    name: 'TEXTO_OC',
                }),
                serverUrlManager: new Field({
                    name: 'URL_SERVIDOR_GERENTE',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDefaultCurrencies extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarMoedasPadrao',
            type: 'SEARCH',
            node: 'MOEDAS.MOEDA',
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetDefaultMapVisualizationCoordinates extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarCoordenadasPadraoVisualizacaoMapa',
            type: 'SEARCH',
            node: 'CONFIG',
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetGeoserverCacheCleanupInfo extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarLimpezaCacheGeoserver',
            type: 'SEARCH',
            node: 'CONFIG',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveGeoserverCacheCleanupInfo extends CallCommand {
    constructor() {
        super({
            name: 'SalvarLimpezaCacheGeoserver',
            type: 'SAVE',
            node: 'CONFIG',
            fields: {
                geoCacheCleanupFrequency: new Field({
                    name: 'geoserver_periodicidade_limpeza_cache',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetGoogleMapsAddressing extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarEnderecamentoGoogleMaps',
            type: 'SEARCH',
            node: 'CONFIG',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetInstanceCountry extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarPaisInstancia',
            type: 'SEARCH',
            node: 'PAIS',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveSmsBillingConfig extends CallCommand {
    constructor() {
        super({
            name: 'SalvarConfigFaturamentoSms',
            type: 'SAVE',
            fields: {
                internalSmsSendingLimit: new Field({
                    name: 'LIMITE_ENVIO_SMS_INTERNO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveSmsBillingConfigGroups extends CallCommand {
    constructor() {
        super({
            name: 'SalvarGruposPermissaoConfigFaturamentoSms',
            type: 'SAVE',
            fields: {
                userGroupIds: new Field({
                    name: 'IDS_GRUPO_PERMISSOES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class RemoveSmsBillingConfig extends CallCommand {
    constructor() {
        super({
            name: 'RemoverCreditosRestantesConfigFaturamentoSms',
            type: 'SAVE',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetSmsBillingConfig extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarConfigFaturamentoSms',
            type: 'SEARCH',
            node: 'CONFIG',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetBellingEditHistory extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarHistoricoAlteracaoConfigFaturamentoSms',
            type: 'SEARCH',
            node: 'HISTORICO_ALTERACAO_CONFIG_FATURAMENTO.HISTORICO_ALTERACAO_CONFIG_FATURAMENTO',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetLocaleConfig extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarLocaleConfig',
            type: 'SEARCH',
            node: 'CONFIG.LOCALE_DEFAULT',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetGeocodeApi extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarGeocodeApi',
            type: 'SEARCH',
            node: 'GEOCODE_APIS.GEOCODE_API',
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class UpdateGeocodeApiPriority extends CallCommand {
    constructor() {
        super({
            name: 'AlterarPrioridadeGeocodeApi',
            type: 'SAVE',
            fields: {
                geocodeApiJson: new Field({
                    name: 'JSON_GEOCODE_API',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetInitialConfigs extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarConfiguracoes',
            type: 'SEARCH',
            node: 'CONFIGS.CONFIG',
            fields: {},
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetGroupSessionPanel extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarGrupoPermissoesSessionPainel',
            type: 'SEARCH',
            node: 'GRUPOS_PERMISSOES_SESSION.GRUPO_PERMISSAO_SESSION',
            fields: {
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                groupsId: new Field({
                    name: 'IDS_GRUPO_PERMISSOES',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveGroupSessionPanel extends CallCommand {
    constructor() {
        super({
            name: 'SalvarGrupoPermissoesSessionPainel',
            type: 'SAVE',
            fields: {
                groupSessionPanelId: new Field({
                    name: 'ID_GRUPO_PERMISSOES_SESSION_PAINEL',
                }),
                domainId: new Field({
                    name: 'ID_DOMINIO',
                }),
                sessionName: new Field({
                    name: 'SESSION_PAINEL',
                }),
                groupId: new Field({
                    name: 'ID_GRUPO_PERMISSOES',
                }),
                status: new Field({
                    name: 'STATUS',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SyncResourceManager extends CallCommand {
    constructor() {
        super({
            name: 'SincronizarResourceManager',
            type: 'NONE',
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class GetFocalWorksites extends CallCommand {
    constructor() {
        super({
            name: 'ConsultarParquesFocal',
            type: 'SEARCH',
            node: 'PARQUES_DE_SERVICO.PARQUE_DE_SERVICO',
            fields: {
                personId: new Field({
                    name: 'ID_PESSOA',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveInstanceFocal extends CallCommand {
    constructor() {
        super({
            name: 'SalvarFocalInstancia',
            type: 'SAVE',
            fields: {
                personId: new Field({
                    name: 'ID_PESSOA',
                }),
                globalFocal: new Field({
                    name: 'FOCAL_GERAL',
                }),
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                insert: new Field({
                    name: 'INSERIR',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
export class SaveRoutingTimeWorksite extends CallCommand {
    constructor() {
        super({
            name: 'SalvarTempoRoteirizacaoParqueServico',
            type: 'SAVE',
            fields: {
                worksiteId: new Field({
                    name: 'ID_PARQUE_SERVICO',
                }),
                routingTime: new Field({
                    name: 'TEMPO_ROTEIRIZACAO',
                }),
            },
        });
    }
    async callCommand(param, config, debug) {
        return super.callCommand(param, config, debug);
    }
}
